import '../css/app.scss';
// import './bootstrap';
import "@/helpers/forceExternalNewWindow";
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Providers from "@/providers";
import moment from "moment";
import 'moment/dist/locale/nl';
import useNMO from "@/helpers/useNMO";
import useGoogleTagManager from "@/helpers/useGoogleTagManager";
import useGdprCookie from "@/helpers/useGdprCookie";

import axios, {AxiosInstance} from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

// Set moment-js language
moment.locale('nl');




// Extend window with custom attributes
declare global {
  interface Window {
    grecaptcha: any;
    KaChing: any;
    axios: AxiosInstance,
    _comscore: object[],
    gdprAppliesGlobally: boolean,
    harvest: any,
    Didomi: any,
  }
}

// // NMO Comscore (ScorecardResearch)
useNMO()
// // Google Analytics and friends using Tag Manager
// useGoogleTagManager()
//
// // gdpr cookie
useGdprCookie()
//




createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    const initProps = props.initialPage.props as any
    const AppContainer = <Providers legacyWidget={false} props={initProps}><App {...props} /></Providers>;

    if (import.meta.env.SSR) {
      hydrateRoot(el, AppContainer);
      return;
    }

    createRoot(el).render(AppContainer);
  },
  progress: {
    color: '#4B5563',
  },
});
