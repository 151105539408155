import {relativeRoute} from "@/helpers/relativeRoute";
import {useRef} from "react";
import {useApp} from "@/Providers/AppProvider";
import logo from '@images/logo/logo.svg';
export type HeaderAuthProps = {
  user: {
    email: string,
    name: string,
    id: string,
  },
  amount_persons: number,
  amount_invitations: number,
}

const Header = ({auth}: {
  auth?: HeaderAuthProps
}) => {


  const {onboarding} = useApp()
  const navRef = useRef<null | HTMLElement>(null)

  const handleToggle = () => {
    navRef.current?.classList.toggle('open')
  }

  const {user, amount_persons, amount_invitations} = auth ?? {};

  return <>

  <header className="bg-white flex justify-between navigation">
    <a href={relativeRoute('home')} className="header__logo">
      <img src={logo} alt="Mensenlinq"/>
    </a>
    <nav ref={navRef}>
      <ul>
        <li><a href={relativeRoute('home')}>Home</a></li>
        <li><a href={relativeRoute('obituary.index')}>Overlijdensberichten</a></li>
        {/*<li>*/}
        {/*  <a href={relativeRoute('herdenkingspagina')}>Herdenkingspagina</a>*/}
        {/*  <ul>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-voorbeeld')}>Voorbeeld herdenkingspagina</a></li>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-faq')}>Veelgestelde vragen herdenkingspagina</a></li>*/}
        {/*    <li><a href={relativeRoute('herdenkingspagina-stappenplan')}>Stappenplan herdenkingspagina</a></li>*/}
        {/*  </ul>*/}
        {/*</li>*/}
    <li><a href={relativeRoute('articles')}>Verhalen</a></li>
    <li><a href={relativeRoute('adressengids')}>Adressengids</a></li>
    <li>
      <a href={relativeRoute('zakelijk')}>Zakelijk</a>
      <ul>
        <li><a href={relativeRoute('online-condoleanceregister')}>Online condoleanceregister</a></li>
        {/*<li><a href={relativeRoute('herdenkingspagina-stappenplan')}>Stappenplan herdenkingspagina</a></li>*/}
        <li><a href={relativeRoute('advertise')} data-testid={'header-advertise'}>Adverteren</a></li>
        <li><a href={relativeRoute('contact')} data-testid={'header-contact'}>Contact opnemen</a></li>
      </ul>
    </li>
        <li>
          <a
            href={onboarding ? relativeRoute(auth ? 'account.person.create' : 'create') : "https://www.familieberichten.nl"}
            data-testid={'header-create'}>Overlijden bekendmaken</a>
          <ul>
            {/*<li><a href={relativeRoute('krant-online')}>Krant + online</a></li>*/}
            <li><a href={relativeRoute('standaard-rouwpagina')}>Standaard rouwpagina</a></li>
            <li><a href={relativeRoute('uitgebreide-rouwpagina')}>Uitgebreide rouwpagina</a></li>
          </ul>
        </li>
      </ul>
      <div className="btn btn__account">
        {auth
          ? <>
          <a href={relativeRoute('account')} title={'Jouw account'} data-testid={'header-account'}><i className="fa-regular fa-user"></i></a>
          <ul>
            <li className="border-b"><a href={relativeRoute('account')} data-testid={'header-account'}>Ingelogd als {user?.email}</a></li>
            <li><a href={relativeRoute('account')} data-testid={'header-persons'}>Rouwberichten ({amount_persons})</a></li>
            {amount_invitations && amount_invitations > 0 ? <li><a href={relativeRoute('invites.index')} data-testid={'header-invitations'}>Uitnodigingen ({amount_invitations})</a></li> : ''}
            <li className="border-b"><a href={relativeRoute('profile.edit')} data-testid={'header-account_settings'}>Instellingen</a></li>
            <li><a href={relativeRoute('logout')} data-testid={'header-logout'}>Uitloggen</a></li>
          </ul>
          </>
          : <a href={relativeRoute('login')} title={'Inloggen'} data-testid={'header-login'}><i className="fa-regular fa-user"></i></a>
        }
  </div>
  <div className="btn btn__toggle" onClick={handleToggle}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>
</header>

</>
}


export default Header
