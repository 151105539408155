
import styles from './Tile.module.scss'
import React from "react";
import {Article} from "@/types/Article.d";
import {relativeRoute} from "@/helpers/relativeRoute";

// interface TileProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
//     person: Person
// }

export default function Tile({article}: {article: Article}) {


    return <article className={`${styles.tile} `}>

        <a className={styles.article} href={relativeRoute('article', {slug: article.slug})}>
            <div className={styles.header}>
                <img src={article.image} alt={article.title}/>

            </div>
            <div className={styles.content}>
                <h2>{article.title}</h2>
                <div dangerouslySetInnerHTML={{__html: article.content}}></div>
            </div>
        </a>

      <div className={styles.tags}>
        {article.tags.map((tag: any) => <a key={tag.slug} href={relativeRoute('article', {slug: article.slug})} className={styles.tag}>{tag.title}</a>)}
      </div>

    </article>
}
