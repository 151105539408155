
import styles from './Panel.module.scss'
import {AnchorHTMLAttributes, ComponentPropsWithoutRef, ElementType} from "react";

interface CardProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: any,
  className?: string,
}

export default <T extends ElementType = 'a'>(
  {
    as,
    message,
    className,
    children,
    ...props
  }:
    ComponentPropsWithoutRef<T> & {
    as?: T
    message?: string
    className?: string
  }) => {

  const Component = as || 'a'
  return <Component className={`${styles.panel} ${className ? className : ''}`} {...props}>{children}</Component>
}
