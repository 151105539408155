
import styles from './CallToAction.module.scss';

export default (
  {
    imageBackground,
    imageFloating,
    title,
    content,
    className,
  }: {
    imageBackground: string
    imageFloating?: string
    title: string
    content: any
    className?: string
  }
) => {
  return <section className={`${styles.container} ${className ?? ''}`}>
    <div>
      <div className={styles.card}>
        <div className={styles.content}>
          <h2>{title}</h2>
          {content}
        </div>
        <div className={styles.images}>
          <img src={imageBackground} alt="" className={styles.background} />
          {imageFloating && <img src={imageFloating} alt="" className={styles.floating} />}
        </div>
      </div>
    </div>

  </section>
}