// import { DidomiSDK } from '@didomi/react';


/**
 * Cookie popup DIDOMI
 *
 */
export default function useGdprCookie() {
// Would be nice to use DidomiSDK
//   <DidomiSDK
//   iabVersion={2}
//   gdprAppliesGlobally={true}
//   onReady={didomi => console.log('Didomi SDK is loaded and ready', didomi)}
//   onConsentChanged={cwtToken => console.log('A consent has been given/withdrawn', cwtToken)}
//   onNoticeShown={() => console.log('Didomi Notice Shown')}
//   onNoticeHidden={() => console.log('Didomi Notice Hidden')}
// />


//De-compiled code
  // Globale GDPR-instelling
  window.gdprAppliesGlobally = true;

  (function () {
    // Functie om een verborgen iframe toe te voegen aan de pagina
    function createIframe(frameName) {
      if (!window.frames[frameName]) {
        if (document.body && document.body.firstChild) {
          const body = document.body;
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.name = frameName;
          iframe.title = frameName;
          body.insertBefore(iframe, body.firstChild);
        } else {
          setTimeout(() => {
            createIframe(frameName);
          }, 5);
        }
      }
    }

    // Functie om de TCF API te initialiseren
    function initializeTCFAPI(n, r, o, c, s) {
      function tcfCommandHandler(command, parameter, callback, version) {
        if (typeof callback !== "function") {
          return;
        }

        if (!window[r]) {
          window[r] = [];
        }

        let intercepted = false;
        if (s) {
          intercepted = s(command, parameter, callback);
        }

        if (!intercepted) {
          window[r].push({ command, parameter, callback, version });
        }
      }

      tcfCommandHandler.stub = true;

      function messageListener(event) {
        // Zorg ervoor dat de functie beschikbaar is en er data aanwezig is
        if (!window[n] || window[n].stub !== true || !event.data) {
          return;
        }

        let isString = typeof event.data === "string";
        let message;

        try {
          message = isString ? JSON.parse(event.data) : event.data;
        } catch (error) {
          return;
        }

        if (message[o]) {
          const { command, parameter, callId, version } = message[o];
          window[n](command, parameter, (returnValue, success) => {
            const response = {
              [c]: { returnValue, success, callId }
            };
            event.source.postMessage(isString ? JSON.stringify(response) : response, "*");
          }, version);
        }
      }

      // Initialiseer de TCF API als deze niet gedefinieerd is
      if (typeof window[n] !== "function") {
        window[n] = tcfCommandHandler;

        if (window.addEventListener) {
          window.addEventListener("message", messageListener, false);
        } else {
          window.attachEvent("onmessage", messageListener);
        }
      }
    }

    // Initialiseer de TCF API en voeg de loader-script toe
    initializeTCFAPI("__tcfapi", "__tcfapiBuffer", "__tcfapiCall", "__tcfapiReturn");
    createIframe("__tcfapiLocator");

    (function addTCFAPILoader(scriptId) {
      const script = document.createElement("script");
      script.id = "spcloader";
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://sdk.privacy-center.org/${scriptId}/loader.js?target=${document.location.hostname}`;
      script.charset = "utf-8";

      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    })("11ef8ac9-6270-4d5e-8b99-8d6a5bd60059");
  })();



}
