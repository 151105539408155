import React, {
    ComponentPropsWithoutRef,
    ElementType,
} from 'react';


const InputError = <T extends ElementType = 'p'>(
  {
      as,
      message,
      className,
      ...props
    }:
    ComponentPropsWithoutRef<T> & {
    as?: T
    message?: string
    className?: string
  }) => {
    const Component = as || 'p'

    return message ? <Component {...props} className={'text-sm text-red-600 ' + className || '' }>
        {message}
    </Component> : null;
}
export default InputError