// Deprecated since 18 sept 2024 @Ard van Midlum
const useHarvest = () => {

    window.harvest = window.harvest || {};
    window.harvest.queue = window.harvest.queue || [];
    window.harvest.trackEvent = function (events: any) {
      window.harvest.queue = window.harvest.queue.concat(events);
    };


  // data-track-page=
  // home: data-track-page="{}"
  // overlijdensberichten: {&quot;postID&quot;:100,&quot;postPublishDate&quot;:&quot;2019-01-31T19:28:28+00:00&quot;}
  // adressengids: {&quot;postID&quot;:6952,&quot;postPublishDate&quot;:&quot;2022-08-22T15:57:00+00:00&quot;}
  // zakelijk: //{&quot;postID&quot;:10594,&quot;postPublishDate&quot;:&quot;2023-11-20T11:54:22+00:00&quot;}
  // in-memoriam: {&quot;postID&quot;:320,&quot;postPublishDate&quot;:&quot;2019-07-31T12:48:53+00:00&quot;}
  // herdenkingspagina: {&quot;postID&quot;:6950,&quot;postPublishDate&quot;:&quot;2022-08-22T13:23:49+00:00&quot;}
  // verhalen: data-track-page="{&quot;postID&quot;:6914,&quot;postPublishDate&quot;:&quot;2022-08-22T12:01:15+00:00&quot;}"
    const script = document.createElement("script");
    const el = document.getElementsByTagName("script")[0];
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.harvest.graindata.com/ndc/prod/harvest.js`;
    el.parentNode?.insertBefore(script, el);
}




export default useHarvest;


