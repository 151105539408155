import {PageProps} from '@/types';
import Hero from "@/Organisms/Hero/Hero";
import ObituaryOverview from "@/Organisms/Obituaries/ObituaryOverview";
import Onboarding from "@/Organisms/CallToAction/Onboarding";
import BlogsLatest from "@/Organisms/Blog/Latest";
import NewsletterSubscribe from "@/Organisms/Newsletter/Subscribe";
import StickyContainer from "@/Molecules/StickyContainer/StickyContainer";
import Page from "@/Pages/Page";
import Commercial, {CommercialPageType, CommercialType} from "@/Atoms/Commercial";
import AdvertisementSection from "@/Organisms/Advertisements/AdvertisementSection";

export default function Home({ obituaries, articles}: PageProps<{ obituaries: [], articles: [] }>) {

  return <Page fullTitle={'Mensenlinq - Samen leven, samen delen'} pageType={CommercialPageType.Home} adUnit={'homepage'}>
    <Hero/>

    <section className="bg-beige section-a">

      <div className="container mx-auto flex flex-col items-center">
        <div className={'intro'}>
          <h2>Overlijdensberichten</h2>
          <p>De meest actuele overlijdensberichten</p>
        </div>
        <StickyContainer
          component={<Commercial type={CommercialType.HalfPageSkyscraper} top />}
          className={'my-5'}
        >
          <ObituaryOverview obituaries={obituaries}/>
        </StickyContainer>
        <div className={'actions'}>
          <a className={'btn'} href={'/overlijdensberichten'}>Toon meer</a>
        </div>
      </div>
    </section>

    <AdvertisementSection className={'bg-beige p-5'} />

    <Onboarding/>

    <section className={'section-a'}>
      <div className="container mx-auto flex flex-col items-center">
        <div className={'intro'}>
          <h2>De laatste verhalen</h2>
          <p>Voor eeuwig en altijd hun verhaal</p>
        </div>
        <StickyContainer component={<Commercial type={CommercialType.HalfPageSkyscraper}/>} >
          <BlogsLatest articles={articles}/>
        </StickyContainer>
        <div className={'actions'}>
          <a className={'btn'} href={'/verhalen'}>Toon meer</a>
        </div>
      </div>
    </section>

    <AdvertisementSection />


    <NewsletterSubscribe/>
  </Page>
}
